







































































































































import Vue, { PropType } from "vue";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ACTION_CANDIDATE,
  AL_AIN_USER_FILTER,
  GET_ALL_CANDIDATES
} from "@/store/modules/recruiter_admin/constants";
import {
  ActionCandidate,
  ActionCandidatePayload
} from "@/store/modules/recruiter_admin/interfaces";
import { TranslateResult } from "vue-i18n";
import AutoCompleteUserAndEidSearch from "@/components/shared/AutoCompleteUserAndEidSearch.vue";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";

export default Vue.extend({
  name: "AllCandidatesHeader",
  components: {
    AutoCompleteUserAndEidSearch
  },
  data() {
    return {
      loading: false,
      al_ain_users_items: [
        {
          text: "Yes",
          value: true
        },
        {
          text: "No",
          value: false
        }
      ],
      filter_overlay: false,
      filter_icon: require("@/assets/icons/bold/filter.svg"),
      filters: [
        { label: this.$t("auth.name"), value: true },
        { label: this.$t("auth.eid-number"), value: false }
      ],
      selected_fitler: this.$t("auth.name") as TranslateResult
    };
  },
  computed: {
    ...mapGetters("recruiter_admin", {
      get_all_candidates: GET_ALL_CANDIDATES,
      get_al_ain_users_filter: AL_AIN_USER_FILTER
    }),
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    },
    al_ain_users_filter: {
      get(): boolean {
        return this.get_al_ain_users_filter;
      },
      set(value: boolean): void {
        this.set_al_ain_filter(value);
      }
    }
  },
  props: {
    filter: {
      type: String as PropType<ViewCandidatesOptions>,
      required: true
    }
  },
  methods: {
    ...mapActions("recruiter_admin", {
      action_candidate: ACTION_CANDIDATE
    }),
    ...mapMutations("recruiter_admin", {
      set_al_ain_filter: AL_AIN_USER_FILTER
    }),
    async approve_all() {
      const ids: number[] = [];
      for (const candidate of this.get_all_candidates.results) {
        if (!candidate.is_account_confirmed) {
          ids.push(candidate.candidate_id);
        }
      }
      this.loading = true;
      const payload: ActionCandidatePayload = {
        action: ActionCandidate.ACTIVATE_EMAIL,
        user_ids: ids
      };
      await this.action_candidate(payload);
      this.loading = false;
      this.get_all_candidates.pagination =
        this.get_all_candidates.pagination + 1;
      this.$emit("refresh");
    },
    toggle_filter_overlay() {
      this.filter_overlay = !this.filter_overlay;
    },
    filter_change(ind: number, filter: TranslateResult) {
      this.selected_fitler = filter;
      this.filters = this.filters.map((filter, index) => {
        return { ...filter, value: ind === index };
      });
      this.filter_overlay = false;
    },
    searched_user(users: SearchUserObject[]) {
      if (users) {
        this.$emit(
          "searched_user",
          users.map((user) => user.id)
        );
      }
    }
  }
});
