






























































































import Vue from "vue";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";
import AllCandidatesHeader from "@/components/recruiter/admin/AllCandidatesHeader.vue";
import CandidateCard from "@/components/recruiter/admin/CandidateCard.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AL_AIN_USER_FILTER,
  GET_ALL_CANDIDATES,
  VIEW_CANDIDATE_DATA,
  VIEW_CANDIDATE_FILTER
} from "@/store/modules/recruiter_admin/constants";
import { GetAllCandidatesPayload } from "@/store/modules/recruiter_admin/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { SiteDirections } from "@/store/modules/common/interfaces";
import {
  SITE_DIRECTION,
  SELECTED_USER_BY_EID_NAME
} from "@/store/modules/common/constants";
import { SearchedCandidate } from "@/store/modules/recruiter/interfaces";
import { USER_DETAILS_BY_ID } from "@/store/modules/auth/constants";
import { User } from "@/interfaces/data_objects/user";
import OnBoardingInvitationCard from "@/components/recruiter/OnBoardingInvitationCard.vue";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";

export default Vue.extend({
  name: "AllCandidates",
  components: {
    DataNotFound,
    CandidateCard,
    AllCandidatesHeader,
    OnBoardingInvitationCard
  },
  data() {
    return {
      candidate_filter:
        ViewCandidatesOptions.REGISTERED as ViewCandidatesOptions,
      loading: true,
      pagination: 1,
      user_object: {} as User,
      on_boarding_invitation: false,
      user_ids: [] as number[]
    };
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION,
      fetch_users: SELECTED_USER_BY_EID_NAME
    }),
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    },
    ...mapGetters("recruiter_admin", {
      get_all_candidates: GET_ALL_CANDIDATES,
      get_candidate_filter: VIEW_CANDIDATE_FILTER,
      al_ain_users_filter: AL_AIN_USER_FILTER
    })
  },
  mounted() {
    this.candidate_filter = this.get_candidate_filter;
    if (this.fetch_users.length > 0) {
      this.user_ids = this.fetch_users.map((user: SearchUserObject) => user.id);
    }
    this.fetch_candidates(this.get_all_candidates.pagination);
  },
  methods: {
    ...mapActions("recruiter_admin", {
      fetch_all_candidates: GET_ALL_CANDIDATES
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    ...mapMutations("recruiter_admin", {
      set_all_candidates: GET_ALL_CANDIDATES,
      set_view_candidates_date: VIEW_CANDIDATE_DATA,
      set_candidate_filter: VIEW_CANDIDATE_FILTER
    }),
    ...mapActions("auth", {
      fetch_user_by_id: USER_DETAILS_BY_ID
    }),
    update_candidate_filter(value: ViewCandidatesOptions) {
      this.candidate_filter = value;
      this.set_candidate_filter(this.candidate_filter);
      this.get_all_candidates.pagination = 1;
      this.fetch_candidates();
    },
    async view_candidate(data: Candidates.Candidates) {
      this.set_view_candidates_date(data);
      this.set_candidate_filter(this.candidate_filter);
      await this.$router.push("view");
    },
    async view_profile(user: SearchedCandidate) {
      if (user) {
        const response = await this.fetch_user_by_id(user.candidate_id);
        this.user_object = response;
        this.on_boarding_invitation = true;
      }
    },
    async fetch_candidates(page = 1, limit = 12) {
      this.loading = true;
      let approved = false;
      if (this.candidate_filter === ViewCandidatesOptions.APPROVED)
        approved = true;
      else if (this.candidate_filter === ViewCandidatesOptions.DECLINED)
        approved = false;
      else if (this.candidate_filter === ViewCandidatesOptions.REGISTERED)
        approved = false;
      const payload: GetAllCandidatesPayload = {
        page: page - 1,
        limit_per_page: limit,
        removed: this.candidate_filter === ViewCandidatesOptions.DECLINED,
        approved,
        registered: true,
        al_ain_users: this.al_ain_users_filter,
        user_ids: this.user_ids
      };
      const response = await this.fetch_all_candidates(payload);
      if (!response) {
        this.set_root_error(`${this.$t("shared.failed-to-get")}`);
        // await this.$router.push("/recruiter/dashboard");
        return;
      }
      this.set_all_candidates(response);
      this.loading = false;
    },
    get_title(number: number): string {
      if (number === 1) return this.$t("recruiter.no-candidates").toString();
      else return this.$t("shared.no-pending").toString();
    },
    on_close() {
      this.on_boarding_invitation = false;
      this.user_object = {} as User;
    },
    async approve_decline_action() {
      this.on_boarding_invitation = false;
      this.user_object = {} as User;
      await this.fetch_candidates(this.get_all_candidates.pagination);
    },
    async searched_user(ids: number[]) {
      if (ids) {
        this.user_ids = ids;
        await this.fetch_candidates(this.get_all_candidates.pagination);
      }
    }
  }
});
